import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import DeviceData from '../helpers/deviceData';
import Debounce from '../helpers/debounce';
import { hasLoggedIn, storageAvailable } from '../helpers/hasLoggedIn';
import Google from '../helpers/google';

// window.addEventListener('load', () => {
//   var test = DeviceData.getDeviceDetails();
//   console.log(test);
// });

const initDeviceWatchers = (setDeviceData) => {
  const refreshDeviceDetails = Debounce(() => {
    setDeviceData(DeviceData.getDeviceDetails());
  }, 250);

  window.matchMedia('(orientation: portrait)').addListener((/*m*/) => {
    // if (m.matches) {
    //   console.log('Changed to portrait');
    // } else {
    //   console.log('Changed to landscape');
    // }
    refreshDeviceDetails();
  });

  window.addEventListener(
    'orientationchange',
    () => {
      refreshDeviceDetails();
    },
    false
  );

  window.addEventListener(
    'resize',
    () => {
      refreshDeviceDetails();
    },
    false
  );
};

const AppProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState('color-0');
  //const [solidHeaderBG, setSolidHeaderBG] = useState(true);
  const [deviceData, setDeviceData] = useState(DeviceData.getDeviceDetails());

  useEffect(() => {
    initDeviceWatchers(setDeviceData);
  }, []);

  const providerObj = {
    open: showModal,
    showModal: () => setShowModal(true),
    hideModal: () => setShowModal(false),
    copyright: (
      <span className="copyright">
        &copy; {new Date().getFullYear()} Paperless Pipeline
      </span>
    ),
    //solidHeaderBG: solidHeaderBG,
    //setSolidHeaderBG: setSolidHeaderBG,
    localStorage: storageAvailable('localStorage'),
    hasLoggedIn: hasLoggedIn(),
    deviceData: deviceData,
    sendGtagEvent: Google.sendGtagEvent,
    goog: Google,
    globalColor: color,
    setGlobalColor: setColor,
  };

  return <Provider value={providerObj}>{children}</Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
